@font-face {
  font-family: 'Chance';
  src: local('Chance') url('./assets/fonts/Chance-Regular.ttf') format('truetype');
  font-weight: 700;
}

html,
body {
  background: transparent;
}

* {
  font-family: 'Inter', sans-serif;
  color: #222222;
  background: transparent !important;
}

*:not(h1, h2, h3, h4, h5, h6) {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.8rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Chance', sans-serif !important;
  color: #2222b3;
}

a,
a > span {
  color: #00b9ff !important;
}

strong {
  font-weight: bold !important;
}
